(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/common/directives/documents/main.tpl.html', '<div class=\"mt-element-list\">\n  <div class=\"mt-list-head list-simple ext-1 font-white dark\">\n    <div class=\"list-head-title-container\">\n      <h3 class=\"list-title\">\n        <i class=\"icon-docs\" style=\"font-size: 20px;\"></i>\n        {{title}}\n      </h3>\n    </div>\n  </div>\n  <div ng-if=\"serverRequest\" ng-switch=\"serverRequest.status\">\n    <span ng-switch-when=\"working\" class=\"label label-warning\">Cargando...</span>\n    <div ng-switch-when=\"error\" server-error=\"serverRequest\"></div>\n    <div ng-switch-when=\"ok\">\n      <div class=\"mt-list-container list-simple ext-1 group\">\n        <div ng-repeat=\"item in files\" documents-item=\"item\"></div>\n      </div>\n    </div>\n  </div>\n  <div ng-if=\"!serverRequest\">\n    <div class=\"mt-list-container list-simple ext-1 group\">\n      <div ng-repeat=\"item in files\" documents-item=\"item\"></div>\n    </div>\n  </div>\n</div>\n');
    }]);
})();