(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/tests/requests/simple.tpl.html', '<div class=\"portlet light\">\n  <div class=\"portlet-title\">\n    <div class=\"caption font-green-sharp\">\n      <i class=\"icon-energy font-green-sharp bold\"></i>\n      <span class=\"caption-subject bold\">Simple - con contenedor</span>\n    </div>\n    <div class=\"actions\">\n      <div blocker-button=\"serverRequest1\" ng-click=\"containerOkRequest()\" class=\"blue\">\n        <i class=\"icon-check\"></i>\n        Ok request\n      </div>\n      <div blocker-button=\"serverRequest1\" ng-click=\"containerErrorRequest()\" class=\"red\">\n        <i class=\"icon-close\"></i>\n        Error request\n      </div>\n    </div>\n  </div>\n  <div class=\"portlet-body\">\n    <div ng-switch=\"serverRequest1.status\" class=\"well\">\n      <span ng-switch-when=\"working\" class=\"label label-warning\">Cargando...</span>\n      <div ng-switch-when=\"error\" server-error=\"serverRequest1\"></div>\n      <div ng-switch-when=\"ok\">\n        result: {{result|json}}\n      </div>\n      <div ng-switch-default=\"\">\n        Aquí se mostrará el resultado\n      </div>\n    </div>\n  </div>\n</div>\n\n<div class=\"portlet light\">\n  <div class=\"portlet-title\">\n    <div class=\"caption font-green-sharp\">\n      <i class=\"icon-energy font-green-sharp bold\"></i>\n      <span class=\"caption-subject bold\">Simple - sin contenedor</span>\n    </div>\n    <div class=\"actions\">\n      <div blocker-button=\"serverRequest2\" ng-click=\"okRequest()\" class=\"blue\">\n        <i class=\"icon-check\"></i>\n        Ok request\n      </div>\n      <div blocker-button=\"serverRequest2\" ng-click=\"errorRequest()\" class=\"red\">\n        <i class=\"icon-close\"></i>\n        Error request\n      </div>\n    </div>\n  </div>\n  <div class=\"portlet-body\"></div>\n</div>\n');
    }]);
})();