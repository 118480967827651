(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/tests/requests/main.tpl.html', '<div class=\"page-content requests-container\">\n  <div class=\"container\">\n    <div class=\"page-content-inner mobil-vspace\">\n      <div class=\"row\">\n        <div class=\"col-xs-12\">\n          <div class=\"portlet light ng-scope\">\n            <div class=\"portlet-title\">\n              <div class=\"caption font-red-sunglo\">\n                <i class=\"icon-wrench font-red-sunglo bold\"></i>\n                <span class=\"caption-subject bold\">Requests</span>\n              </div>\n            </div>\n            <div class=\"portlet-body\">\n              <a href=\"\" ui-sref=\"main.views.tests.requests.list\">Lista</a>\n              | <a href=\"\" ui-sref=\"main.views.tests.requests.simple({topScroll: true})\">Simple</a>\n              | <a href=\"\" ui-sref=\"main.views.tests.requests.confirm\">Confirmación</a>\n              | <a href=\"\" ui-sref=\"main.views.tests.requests.form.insert\">Formulario</a>\n              | <a href=\"\" ui-sref=\"main.views.tests.requests.upload\">Subida de archivo</a>\n            </div>\n          </div>\n        </div>\n      </div>\n\n      <div class=\"row\">\n        <div ui-view=\"\" class=\"col-xs-12\"></div>\n      </div>\n    </div>\n  </div>\n</div>\n');
    }]);
})();