(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/tests/requests/confirm.tpl.html', '<div class=\"modal fade in\" tabindex=\"-1\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" ng-click=\"close()\" class=\"close\"></button>\n        <h4 class=\"modal-title\">Confirmación</h4>\n      </div>\n      <div class=\"modal-body\">\n        <p>Por favor confirme la operación</p>\n        <div server-error=\"serverRequest\"></div>\n      </div>\n      <div class=\"modal-footer\">\n        <div ng-if=\"scope === \'ok\'\" blocker-button=\"serverRequest\" ng-click=\"okConfirm(model)\" class=\"green\">\n          <i class=\"icon-check\"></i>\n          Confirmar\n        </div>\n        <div ng-if=\"scope === \'error\'\" blocker-button=\"serverRequest\" ng-click=\"errorConfirm(model)\" class=\"green\">\n          <i class=\"icon-check\"></i>\n          Confirmar\n        </div>\n        <div simple-button=\"\" ng-click=\"close()\" class=\"default\">\n          <i class=\"icon-close\"></i>\n          Cerrar\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n');
    }]);
})();