(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/infographic/infographic.tpl.html', '<div class=\"page-head\">\n  <div class=\"container\">\n    <div class=\"page-title\">\n      <h1>Cómo funciona Runap?</h1>\n    </div>\n  </div>\n</div>\n\n<div class=\"page-content\">\n  <div class=\"container\">\n    <ul class=\"page-breadcrumb breadcrumb\">\n      <li>\n        <a href=\"/\" ui-sref=\"main.views.home\">Inicio</a>\n        <i class=\"fa fa-circle\"></i>\n      </li>\n      <li>\n        <span>Cómo funciona Runap?</span>\n      </li>\n    </ul>\n\n    <div class=\"page-content-inner\">\n      <div class=\"portlet light\">\n        <div class=\"portlet-body\">\n          <img src=\"img/content/infografia.jpg\" style=\"max-width: 100%;\" />\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n');
    }]);
})();