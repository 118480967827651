(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/footer/footer.tpl.html', '<!-- <div class=\"container\">\n  <p style=\"font-weight: bold; margin-top: 0;\">\n    <!- Fecha actualización información: automatica ->\n    2023 © Parques Nacionales Naturales de Colombia / Fecha actualización información: {{ yesterday | date: \'dd/MM/yyyy\' }} / Fecha actualización shapefile consolidado: {{ fechaActualizacionShape | date: \'dd/MM/yyyy\' }}\n  </p>\n  <p>\n    La información cartográfica del SINAP se encuentra en el sistema de referencia MAGNA SIRGAS, el área y perímetro de cada una de las áreas protegidas inscritas se calcula en el origen oficial establecido para el país por el Instituto Geográfico Agustín Codazzi (IGAC), adoptado bajo la resolución 370 del 2021 \"Por medio de la cual se establece el sistema de proyección cartográfica oficial para Colombia\".\n  </p>\n  <p>\n    Dado que los cálculos de áreas se realizan de acuerdo con los insumos e instrumentos cartográficos disponibles en la época de la declaratoria de cada una de las áreas protegidas, se pueden presentar diferencias entre la extensión mencionada en los actos administrativos (hectáreas) y la información geográfica del límite del área protegida (hectáreas geográficas), ya que esta última es calculada a partir de algoritmos del software GIS utilizado para tal fin. Por esta razón, en los reportes del RUNAP se incluyen ambas cifras.\n  </p>\n  <p>\n    Las diferencias de cifras de área y perímetro de un área protegida, resultantes del cálculo con diferentes orígenes cartográficos, NO obedecen a una ampliación o disminución de la extensión del área protegida.\n  </p>\n</div> -->\n');
    }]);
})();