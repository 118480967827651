(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/tests/requests/upload.tpl.html', '<div class=\"modal hide fade in\" tabindex=\"-1\" aria-hidden=\"false\" style=\"display: block;\">\n  <div class=\"modal-header\">\n    <h3>{{title}}</h3>\n  </div>\n  <div class=\"modal-body\">\n    <p>Por favor seleccione el archivo que desea subir.</p>\n\n    <div ng-show=\"serverRequest.status === \'initialized\'\">\n      <div ngf-drop=\"\" ngf-select=\"\" ng-model=\"files\" class=\"drop-box\"\n          ngf-drag-over-class=\"dragover\" ngf-multiple=\"false\" ngf-allow-dir=\"false\"\n          accept=\"application/pdf\" ngf-change=\"fileSelected($files, $event, model, flag)\">Arrastre el archivo hasta aquí o haga click para seleccionar el archivo</div>\n      <div ngf-no-file-drop=\"\">El arrastre de archivos no es soportado por este navegador.</div>\n    </div>\n\n    <div ng-show=\"serverRequest.status === \'working\'\">\n      Subiendo el archivo <strong>\"{{files[0].name}}\"</strong>. Por favor espere.\n      <div class=\"progress progress-striped progress-danger active\">\n        <div class=\"bar\" ng-style=\"progressStyle\"></div>\n      </div>\n    </div>\n\n    <div server-error=\"serverRequest\"></div>\n  </div>\n  <div class=\"modal-footer\">\n    <div blocker-button=\"serverRequest\" ng-click=\"cancel()\">\n      <i class=\"icon-close\"></i>\n      Cerrar\n    </div>\n  </div>\n</div>\n');
    }]);
})();