(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/top_bar/top_bar.tpl.html', '<div class=\"pnn-header-container1\">\n  <div class=\"pnn-header-inner-container\">\n    <div class=\"left-pane\">\n      <div class=\"pnn-header-logo-govco\"></div>\n      <div class=\"pnn-header-separator\"></div>\n      <div class=\"pnn-header-logo-runap\">\n        <div class=\"logo-runap-img1\"></div>\n        <div class=\"logo-runap-img2\"></div>\n        <div class=\"break\"></div>\n        <div class=\"logo-runap-text text-center\">\n          Registro Único Nacional de Áreas Protegidas\n        </div>\n      </div>\n    </div>\n    <div class=\"right-pane\">\n      <!-- BEGIN RESPONSIVE MENU TOGGLER -->\n      <a href=\"javascript:;\" class=\"menu-toggler\"></a>\n      <!-- END RESPONSIVE MENU TOGGLER -->\n    </div>\n  </div>\n</div>\n<div class=\"pnn-header-container2\">\n  <div class=\"pnn-header-inner-container\">\n    <div class=\"left-pane\">\n      <!-- <div class=\"pnn-header-logo-potencia-vida\"></div> -->\n      <div class=\"pnn-header-logo-entidad\"></div>\n    </div>\n    <div class=\"right-pane text-right\">\n      <a ng-click=\"login()\">Iniciar sesión</a>\n      <div class=\"search-form\">\n        <!-- <div class=\"input-group\">\n          <input type=\"text\" class=\"form-control\" placeholder=\"Buscar\" name=\"query\">\n          <span class=\"input-group-btn\">\n            <a href=\"javascript:;\" class=\"btn submit\">\n              <i class=\"icon-magnifier\"></i>\n            </a>\n          </span>\n        </div> -->\n        <div class=\"input-group\">\n          <input type=\"hidden\" class=\"search\" />\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n');
    }]);
})();