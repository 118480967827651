(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/common/directives/charts/am_chart.tpl.html', '<div ng-switch=\"serverRequest.status\">\n  <span ng-switch-when=\"working\" class=\"label label-warning\">Cargando...</span>\n  <div ng-switch-when=\"error\" server-error=\"serverRequest\"></div>\n  <div ng-switch-when=\"ok\">\n    <div ng-attr-id=\"{{id}}\" style=\"width: 100%; height: 30px; display: inline-block; vertical-align: middle;\"></div>\n  </div>\n</div>\n');
    }]);
})();