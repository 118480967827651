(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/area/gallery.tpl.html', '<div class=\"portlet light\" style=\"padding-bottom: 20px;\">\n  <div class=\"portlet-title\">\n    <div class=\"caption font-red-sunglo\">\n      <i class=\"icon-info font-red-sunglo bold\" style=\"font-size: 20px; margin-top: 2px;\"></i>\n      <span class=\"caption-subject bold\" style=\"font-size: 20px;\">Galería de imágenes</span>\n    </div>\n  </div>\n\n  <div ng-switch=\"imagesSR.status\" class=\"portlet-body\">\n    <span ng-switch-when=\"working\" class=\"label label-warning\">Cargando...</span>\n    <div ng-switch-when=\"error\" server-error=\"imagesSR\"></div>\n    <div ng-switch-when=\"ok\">\n      <div ng-if=\"images.length === 0\">\n        <div class=\"note note-info\">\n          <h3>Información</h3>\n          <p>No hay imágenes para esta área protegida</p>\n        </div>\n      </div>\n      <div ng-if=\"images.length > 0\" gallery=\"images\"></div>\n    </div>\n  </div>\n</div>\n');
    }]);
})();