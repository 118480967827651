(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/common/directives/documents/item.tpl.html', '<a href=\"\" ng-click=\"collapsed = !collapsed\" class=\"list-toggle-container\" ng-class=\"{collapsed: collapsed}\">\n  <div class=\"list-toggle\" ng-class=\"{done: !collapsed}\">\n    {{item.category}}\n    <span class=\"badge badge-default pull-right bg-white font-green bold\">{{item.files.length}}</span>\n  </div>\n</a>\n<div class=\"panel-collapse collapse\" ng-class=\"{in: !collapsed}\">\n  <ul ng-if=\"(!item.files || item.files.length === 0)\">\n    <li class=\"mt-list-item done\">\n      <div class=\"list-item-content text-danger\" style=\"font-style: italic;\">\n        <i class=\"icon-info\"></i>\n        No hay archivos\n      </div>\n    </li>\n  </ul>\n  <ul ng-if=\"(item.files && item.files.length > 0)\">\n    <li ng-repeat=\"file in item.files\" class=\"mt-list-item done\">\n      <div class=\"list-icon-container\">\n        <i class=\"icon-check\"></i>\n      </div>\n      <div class=\"list-datetime\">{{file.date}}</div>\n      <div class=\"list-item-content\">\n        <h3>\n          <a href=\"\" ng-if=\"item.category === \'Shapes\'\" ng-href=\"{{file.urls.zipshape}}\">{{file.nombre}}</a>\n          <a href=\"\" ng-if=\"item.category === \'Instrumentos de planeación\' && file.tipo_documento === \'PM\'\" ng-href=\"{{file.url}}\">Plan de manejo ({{file.fecha_inicio}})</a>\n          <a href=\"\" ng-if=\"item.category === \'Instrumentos de planeación\' && file.tipo_documento === \'REM\'\" ng-href=\"{{file.url}}\">REM ({{file.fecha_inicio}})</a>\n          <a href=\"\" ng-if=\"item.category !== \'Shapes\' && item.category !== \'Instrumentos de planeación\'\" ng-href=\"{{file.url}}\">{{file.declaratoria}} ({{file.resolucion}})</a>\n        </h3>\n      </div>\n    </li>\n  </ul>\n</div>\n');
    }]);
})();