(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/area/chart.tpl.html', '<div class=\"portlet light about-text\">\n  <h4>\n    <i class=\"icon-pie-chart\"></i>\n    Distribución de área protegida\n  </h4>\n\n  <!-- <div class=\"portlet-title\">\n    <div class=\"caption\">\n      <i class=\"icon-pie-chart font-yellow-casablanca\"></i>\n      <span class=\"caption-subject bold font-yellow-casablanca\">Distribución de área</span>\n    </div>\n  </div> -->\n  <div class=\"portlet-body\">\n    <div class=\"btn-group chart-buttons\">\n      <label ng-repeat=\"chart in charts\" class=\"btn btn-info\" ng-class=\"{active: (chart.value === common.model.chart)}\">\n        <input type=\"radio\" ng-model=\"common.model.chart\" ng-value=\"chart.value\" ng-change=\"changeChart(common.model.chart)\" class=\"toggle\" /> {{chart.label}}\n      </label>\n    </div>\n    <div chart=\"chartOptions\" server-request=\"chartSR\" style=\"min-height: 300px; margin-top: 1.5em;\"></div>\n  </div>\n</div>\n');
    }]);
})();