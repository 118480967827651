(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/tests/requests/model_item.tpl.html', '<td>{{model.name}}</td>\n<td>{{model.lastname}}</td>\n<td>{{model.email}}</td>\n');
    }]);
})();