(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/common/directives/errors/field_error.tpl.html', '<div ng-show=\"(!form[inputName].hidden && form[inputName].$dirty && form[inputName].$invalid) || formObj.serverRequest.error.formFields[modelAttr]\" class=\"text-error\">\n  <div ng-messages=\"form[inputName].$error\">\n    <div ng-message=\"required\">El campo es requerido</div>\n    <div ng-message=\"minlength\">Your field is too short</div>\n    <div ng-message=\"maxlength\">Your field is too long</div>\n    <div ng-message=\"email\">Error de email</div>\n    <div ng-message=\"max\">Error de max</div>\n    <div ng-message=\"min\">Error de min</div>\n    <div ng-message=\"number\">Error de number</div>\n    <div ng-message=\"pattern\">Error de pattern</div>\n    <div ng-message=\"url\">Error de url</div>\n    <div ng-message=\"date\">Error de date</div>\n    <div ng-message=\"datetimelocal\">Error de datetimelocal</div>\n    <div ng-message=\"time\">Error de time</div>\n    <div ng-message=\"week\">Error de week</div>\n    <div ng-message=\"month\">Error de month</div>\n  </div>\n\n  <div ng-switch=\"formObj.serverRequest.error.formFields[modelAttr].length\">\n    <div ng-switch-when=\"1\">\n      {{formObj.serverRequest.error.formFields[modelAttr][0]}}\n    </div>\n    <div ng-switch-default=\"\" ng-repeat=\"fieldError in formObj.serverRequest.error.formFields[modelAttr]\">\n      - {{fieldError}}\n    </div>\n  </div>\n</div>\n');
    }]);
})();