(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/tests/requests/form_container.tpl.html', '<div class=\"portlet light\">\n  <div class=\"portlet-title\">\n    <div class=\"caption font-green-sharp\">\n      <i class=\"icon-note font-green-sharp bold\"></i>\n      <span class=\"caption-subject bold\">Formulario</span>\n    </div>\n    <div class=\"actions\">\n      <div simple-button=\"\" ui-sref=\"main.views.tests.requests.form.insert\" class=\"blue\">\n        <i class=\"icon-check\"></i>\n        Insertar\n      </div>\n      <div simple-button=\"\" ui-sref=\"main.views.tests.requests.form.update({modelId: 123})\" class=\"blue\">\n        <i class=\"icon-check\"></i>\n        Editar\n      </div>\n      <div simple-button=\"\" ng-click=\"showForm()\" class=\"blue\">\n        <i class=\"icon-check\"></i>\n        Insertar en modal\n      </div>\n      <div simple-button=\"\" ng-click=\"showForm(model)\" class=\"blue\">\n        <i class=\"icon-check\"></i>\n        Editar en modal\n      </div>\n    </div>\n  </div>\n  <div class=\"portlet-body\"></div>\n</div>\n\n<div ui-view=\"\"></div>\n');
    }]);
})();