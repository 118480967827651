(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/common/directives/errors/server_error.tpl.html', '<div ng-show=\"serverRequest.status === \'error\'\" class=\"note note-danger\">\n  <h3 ng-show=\"serverRequest.error.message.intro\">{{serverRequest.error.message.intro}}</h3>\n  <p>{{serverRequest.error.message.main}}</p>\n  <p ng-show=\"serverRequest.error.message.extra\">{{serverRequest.error.message.extra}}</p>\n</div>\n');
    }]);
})();