(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/area/timeline_item.tpl.html', '<li ng-class=\"class\" ng-attr-data-date=\"{{item.date}}\">\n  <div ng-repeat=\"document in item.documents\">\n    <div class=\"mt-title\">\n      <h2 class=\"mt-content-title\">{{document.tipo}}</h2>\n    </div>\n    <div class=\"mt-author\">\n      <div class=\"mt-author-name\">\n        <a href=\"\" class=\"font-blue-madison\">Actos administrativos {{document.resolucion}}</a>\n      </div>\n      <div class=\"mt-author-datetime font-grey-mint\">{{item.label}}</div>\n    </div>\n    <div class=\"clearfix\"></div>\n    <div class=\"mt-content border-grey-steel\">\n      <p>{{document.descripcion}}</p>\n    </div>\n  </div>\n</li>\n');
    }]);
})();