(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/tests/requests/upload_view.tpl.html', '<div class=\"row\">\n  <div class=\"span12\">\n    <h2>Subida de archivo</h2>\n    <div simple-button=\"\" ng-click=\"okUploadFile(model)\" class=\"blue\">\n      <i class=\"icon-cloud-upload\"></i>\n      Ok request\n    </div>\n    <div simple-button=\"\" ng-click=\"errorUploadFile(model)\" class=\"red\">\n      <i class=\"icon-close\"></i>\n      Error request\n    </div>\n  </div>\n</div>\n');
    }]);
})();