(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/category/areas.tpl.html', '<div class=\"portlet light\" style=\"padding: 0;\">\n  <h4 class=\"bg-blue-steel bg-font-blue-steel\" style=\"font-size: 20px; font-weight: 600; color: #fff; padding: 0.85em 20px; margin: 0;\">\n    <i class=\"icon-pie-chart\"></i>\n    Áreas protegidas asociadas\n  </h4>\n\n  <div class=\"portlet-body\" style=\"padding: 15px 20px 20px 20px;\">\n    <div style=\"max-height: 497px; overflow-y: auto;\">\n      Número de área protegidas en la categoría {{categoria.termino}}: {{numberAreas}}\n\n      <table ng-if=\"traslapes.length > 0\" class=\"table table-bordered table-striped table-condensed flip-content\" style=\"margin-top: 10px; margin-bottom: 0;\">\n        <thead class=\"flip-content\">\n        <tr>\n          <th>Categoría</th>\n          <th>Nombre</th>\n          <th class=\"numeric\">ha</th>\n        </tr>\n        </thead>\n        <tbody>\n        <tr ng-repeat=\"traslape in traslapes\">\n          <td>{{traslape.area.categoria}}</td>\n          <td>\n            <a href=\"\" ui-sref=\"main.views.area.views({areaId: traslape.contexto_id})\">{{traslape.contexto_termino}}</a>\n          </td>\n          <td>{{traslape.area.geografica.total|number}}</td>\n        </tr>\n        </tbody>\n      </table>\n    </div>\n  </div>\n</div>\n');
    }]);
})();