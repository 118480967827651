(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/area/documents.tpl.html', '<div documents=\"files\" title=\"Documentos asociados\"></div>\n');
    }]);
})();