(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/organization/main.tpl.html', '<div class=\"page-content department-container\">\n  <div class=\"container\">\n    <div class=\"page-content-inner mobil-vspace\">\n      <div class=\"row\">\n        <div ui-view=\"intro\" class=\"col-xs-12\"></div>\n      </div>\n\n      <!-- @TODO: borrar -->\n      <!-- <div class=\"row\">\n        <div ui-view=\"timeline\" class=\"col-md-12\"></div>\n      </div> -->\n\n      <div class=\"row\">\n        <div ui-view=\"chart\" class=\"col-md-7\"></div>\n        <div ui-view=\"documents\" class=\"col-md-5\"></div>\n      </div>\n\n      <div class=\"row\">\n        <div ui-view=\"timeSeries\" class=\"col-md-12\"></div>\n      </div>\n    </div>\n  </div>\n</div>\n');
    }]);
})();