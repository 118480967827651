(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/department/intro.tpl.html', '<div class=\"portlet light\">\n  <div class=\"portlet-title\">\n    <div class=\"caption font-red-sunglo\">\n      <i class=\"icon-info font-red-sunglo bold\" style=\"font-size: 20px; margin-top: 2px;\"></i>\n      <span class=\"caption-subject bold\" style=\"font-size: 20px;\">Departamento {{departamento.nombre}}</span>\n    </div>\n  </div>\n  <div class=\"portlet-body\">\n    <div class=\"row\">\n      <div class=\"col-md-5 areas-charts\">\n        <strong>Número de área protegidas:</strong> {{numberAreas}}\n\n        <table class=\"area\" style=\"margin-top: 15px;\">\n          <tbody>\n          <tr>\n            <td colspan=\"2\" class=\"bold\">Área geográfica (terrestre / marítima)</td>\n          </tr>\n          <tr ng-if=\"geografica.total > 0\">\n            <td colspan=\"2\">\n              <div am-chart=\"{{chart1.id}}\" server-request=\"areasChartsSR\" values=\"chart1.values\" colors=\"chart1.colors\"></div>\n            </td>\n          </tr>\n          <tr ng-class=\"{\'no-chart\': (geografica.total <= 0)}\">\n            <td>{{geografica.terrestre|number:2}} Hectáreas</td>\n            <td class=\"text-right\">{{geografica.maritima|number:2}} Hectáreas</td>\n          </tr>\n          </tbody>\n        </table>\n      </div>\n      <div class=\"col-md-7\">\n        <div id=\"map\"></div>\n      </div>\n    </div>\n  </div>\n</div>\n');
    }]);
})();