(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/common/directives/buttons/simple.tpl.html', '<button type=\"button\" class=\"btn\">\n  <span ng-transclude=\"\"></span>\n</button>\n');
    }]);
})();