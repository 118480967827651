(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/layouts/main.tpl.html', '<div class=\"page-header\">\n  <div ui-view=\"header\" class=\"page-header-top\"></div>\n  <div ui-view=\"megaMenu\" class=\"page-header-menu\"></div>\n</div>\n\n<div class=\"page-container\">\n  <div ui-view=\"content\" class=\"page-content-wrapper\"></div>\n</div>\n\n<div ui-view=\"footer\" class=\"page-footer\"></div>\n<div ui-view=\"preFooter\" class=\"footer-container\"></div>\n\n\n<!-- <div class=\"page-footer logos\">\n  <div class=\"container\">\n    <img src=\"img/logos/pnn.png\" style=\"height: 84px;\" />\n  </div>\n</div> -->\n\n<div class=\"scroll-to-top\">\n  <i class=\"icon-arrow-up\"></i>\n</div>\n');
    }]);
})();