(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/tests/requests/confirm_view.tpl.html', '<div class=\"portlet light\">\n  <div class=\"portlet-title\">\n    <div class=\"caption font-green-sharp\">\n      <i class=\"icon-question font-green-sharp bold\"></i>\n      <span class=\"caption-subject bold\">Confirmación</span>\n    </div>\n    <div class=\"actions\">\n      <div simple-button=\"\" ng-click=\"okRequest()\" class=\"blue\">\n        <i class=\"icon-check\"></i>\n        Ok request\n      </div>\n      <div simple-button=\"\" ng-click=\"errorRequest()\" class=\"red\">\n        <i class=\"icon-close\"></i>\n        Error request\n      </div>\n    </div>\n  </div>\n</div>\n');
    }]);
})();