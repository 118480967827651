(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/layouts/error404.tpl.html', '<div class=\"container error-404\">\n  <h1>404</h1>\n  <h2>Houston, tenemos un problema.</h2>\n  <p>La página que usted está buscando no existe.</p>\n  <p>\n    <a href=\"\" ui-sref=\"main.views.home\" class=\"btn red btn-outline\">Regresar</a>\n    <br />\n  </p>\n</div>\n');
    }]);
})();