(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/modules/department/timeline.tpl.html', '<div class=\"portlet light portlet-fit\">\n  <h4 class=\"bg-yellow bg-font-yellow\" style=\"font-size: 20px; font-weight: 600; color: #fff; padding: 0.85em 20px; margin: 0;\">\n    <i class=\"icon-calendar\"></i>\n    Línea de tiempo\n  </h4>\n\n  <div class=\"portlet-body\" style=\"padding: 15px 20px 20px 20px;\">\n    <div ng-if=\"timelineItems.length === 0\" class=\"note note-info\">\n      <h3>Información</h3>\n      <p>No hay registros para este departamento</p>\n    </div>\n    <div ng-if=\"timelineItems.length > 0\" class=\"cd-horizontal-timeline mt-timeline-horizontal\">\n      <div class=\"timeline\">\n        <div class=\"events-wrapper\">\n          <div class=\"events\">\n            <ol>\n              <li ng-repeat=\"item in timelineItems\" timeline-date=\"item\" index=\"{{$index}}\"></li>\n            </ol>\n            <span class=\"filling-line bg-red\" aria-hidden=\"true\"></span>\n          </div>\n        </div>\n        <ul class=\"cd-timeline-navigation mt-ht-nav-icon\">\n          <li>\n            <a href=\"#0\" class=\"prev inactive btn btn-outline red md-skip\">\n              <i class=\"fa fa-chevron-left\"></i>\n            </a>\n          </li>\n          <li>\n            <a href=\"#0\" class=\"next btn btn-outline red md-skip\">\n              <i class=\"fa fa-chevron-right\"></i>\n            </a>\n          </li>\n        </ul>\n      </div>\n      <div class=\"events-content\">\n        <ol>\n          <li ng-repeat=\"item in timelineItems\" timeline-item=\"item\" index=\"{{$index}}\"></li>\n        </ol>\n      </div>\n    </div>\n  </div>\n</div>\n');
    }]);
})();