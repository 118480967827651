(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/common/directives/buttons/paginator.tpl.html', '<button type=\"button\" ng-switch=\"paginator.button.status\" ng-click=\"paginator.nextPage()\" ng-disabled=\"paginator.button.status !== \'ok\'\" class=\"btn btn-info btn-block\">\n  <span ng-switch-when=\"working\">\n    <span class=\"icon-clock\"></span>\n    Cargando...\n  </span>\n  <span ng-switch-default=\"\" ng-transclude=\"\"></span>\n</button>\n');
    }]);
})();