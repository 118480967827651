(function() {
    var module;

    try {
        // Get current templates module
        module = angular.module('templates');
    } catch (error) {
        // Or create a new one
        module = angular.module('templates', []);
    }

    module.run(["$templateCache", function($templateCache) {
        $templateCache.put('app/layouts/error500.tpl.html', '<div class=\"row\">\n  <div class=\"col-md-12 page-500\">\n    <div class=\"number font-red\">500</div>\n    <div class=\"details\">\n      <h3>Lo sentimos! Algo no va bien.</h3>\n      <p ng-if=\"error\">\n        {{error|json}}\n        <br />\n      </p>\n      <p ng-if=\"!error\">\n        Estamos trabajando en arreglarlo! Por favor vuelva en un rato.\n        <br />\n      </p>\n      <p>\n        <a href=\"\" ui-sref=\"main.views.home\" class=\"btn red btn-outline\">Regresar</a>\n        <br />\n      </p>\n    </div>\n  </div>\n</div>\n');
    }]);
})();